.featured__header {
    text-align: center;
    background-image: linear-gradient(36deg, #a100ff 0%, #ff00c3 62%);
    color: #f2f2f2;
}

.featured__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 1 / 1 / 3 / 3; }
    .div2 { grid-area: 1 / 1 / 2 / 2; }
    .div3 { grid-area: 1 / 2 / 2 / 3; }
    .div4 { grid-area: 2 / 1 / 3 / 2; }
    .div5 { grid-area: 2 / 2 / 3 / 3; }

.featured__img {
    display: flex;
    justify-content: center;
}

.featured__img > img {
    width: 75%;
}

@media (min-width: 750px) {
    .featured__header > h2 {
        font-size: 32px;
        padding: .25% 0;
    }
}

@media (min-width: 1000px) {
    .featured__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 1 / 1 / 2 / 2; }
        .div2 { grid-area: 1 / 2 / 2 / 3; }
        .div3 { grid-area: 1 / 3 / 2 / 4; }
        .div4 { grid-area: 1 / 4 / 2 / 5; }
}