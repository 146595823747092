.Footer {
    padding: 0 5%;
    text-align: center;
}

.footer__logo > a > img {
    width: 100%;
}

.footer__container > div > h2 {
    background: -webkit-linear-gradient(36deg, #a100ff 0%, #ff00c3 62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent; /* Fallback for non-WebKit browsers */
}

.footer__container > div > a {
    text-decoration: none;
    color: #333;
}

@media (min-width: 750px) {
    .Footer {
        padding-top: 5%;
    }
    .footer__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 1 / 1 / 2 / 2; }
        .div2 { grid-area: 1 / 2 / 2 / 3; }
        .div3 { grid-area: 1 / 3 / 2 / 4; }
        .div4 { grid-area: 1 / 4 / 2 / 5; }

    .visitUs > h2,
    .policies > h2,
    .social > h2 {
        font-size: 16px;
    }

    .visitUs > a,
    .policies > a,
    .social > a {
        font-size: 14px;
     }

}