.faq__img,
.q__and__a {
    padding: 0 5%;
}
.faq__header {
    text-align: center;
    background-image: linear-gradient(36deg, #a100ff 0%, #ff00c3 62%);
    color: #f2f2f2;
}

.faq__img > img {
    width: 100%;
}

.q__and__a h3 {
    background: -webkit-linear-gradient(36deg, #a100ff 0%, #ff00c3 62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent; /* Fallback for non-WebKit browsers */
}

@media (min-width: 750px) {
    .faq__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 1 / 1 / 2 / 2; }
        .div2 { grid-area: 1 / 2 / 2 / 3; }

    .faq__img {
        display: flex;
    }
}