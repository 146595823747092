.hero__text {
    padding: 5%;
    color: #f2f2f2;
}

.hero__text > h1 {
    font-weight: 700;
    font-size: 32px;
}

.sizing__container {
    display: flex;
    justify-content: space-around;
    padding: 5% 0;
}

.hero__text__container {
    text-align: center;
    background-image: linear-gradient(36deg, #a100ff 0%, #ff00c3 62%);

}

.name__and__size {
    display: flex;
    flex-direction: column;
    padding: 0%;
}

.name__and__size > h4 {
    margin: 5% 0;
    text-align: center;
}

.hero__text > a > button {
    margin-top: 12px;
    padding: 9px 15px;
    width: 45%;
    font-size: 14px;
    color: #ff00c3;
    border-radius: 20px;
    border: none;
    cursor: pointer;
}

.hero__text > button:hover {
    background-color: #a100ff;
    color: white;
}

.hero__img > img {
    width: 100%;
    height: auto;
}

@media (min-width: 750px) {
    .Hero {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 1 / 1 / 2 / 3; }
        .div2 { grid-area: 1 / 1 / 2 / 2; }
        .div3 { grid-area: 1 / 2 / 2 / 3; }

        .hero__container,
        .hero__img {
            display: flex;
        }
}

@media (min-width: 1000px) {
    .hero__text__container {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .hero__text > button {
        font-size: 16px;
    }
}

@media (min-width: 1250px) {
    .hero__text__container {
        justify-content: center;
    }

    .hero__text > h1 {
        font-size: 40px;
    }

    .hero__text > h4 {
        font-size: 20px;
    }
}