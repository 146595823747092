.Box {
    text-align: center;
}

.item__container > img {
    width: 100%;
}

.box__container {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 6 columns */
    grid-template-rows: auto auto; /* Adjust row height automatically */
    justify-items: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    grid-gap: 10px; /* Adjust the gap as needed */
}

/* Adjustments for each .item__container to span the correct columns */
.item__container:nth-child(1) { grid-area: 1 / 1 / 2 / 3; } /* 1st image on top row, spans columns 1-2 */
.item__container:nth-child(2) { grid-area: 1 / 3 / 2 / 5; } /* 2nd image on top row, spans columns 3-4 */
.item__container:nth-child(3) { grid-area: 1 / 5 / 2 / 7; } /* 3rd image on top row, spans columns 5-6 */

/* Bottom row, center-aligned adjustments */
.item__container:nth-child(4) { grid-area: 2 / 2 / 3 / 4; } /* 1st image on bottom row, centered, spans columns 2-3 */
.item__container:nth-child(5) { grid-area: 2 / 4 / 3 / 6; } /* 2nd image on bottom row, centered, spans columns 4-5 */

.Box > a > button {
    width: 43%;
    height: 50px;
    border: solid 3px #a100ff;
    border-radius: 20px;
    font-size: 16px;
    color: #ff00c3;
    cursor: pointer;
}

.Box > a > button:hover {
    background-color: #a100ff;
    color: #f2f2f2;
}

@media (min-width: 1000px) {
    .box__container {
        grid-template-columns: repeat(5, 1fr); /* Adjusts to 5 columns for wider screens */
        grid-template-rows: auto; /* Single row to ensure all items are in one line */
    }

    /* Re-target and adjust each .item__container for the 5-column layout */
    .item__container:nth-child(1) { grid-column: 1 / 2; grid-row: 1; }
    .item__container:nth-child(2) { grid-column: 2 / 3; grid-row: 1; }
    .item__container:nth-child(3) { grid-column: 3 / 4; grid-row: 1; }
    .item__container:nth-child(4) { grid-column: 4 / 5; grid-row: 1; }
    .item__container:nth-child(5) { grid-column: 5 / 6; grid-row: 1; }
}
