.login__dashboard {
    border: 2px solid #ff00c3;
    border-radius: 5px;
    margin: 25%;
}

.login__header {
    background-color: #ff00c3;
    padding: 5%;
}

.login__header > img {
    background-color: #f2f2f2;
    width: 20%;
    display: flex;
    border-radius: 5px;
}

.login__form {
    margin: 5%;
}

.login__form > button {
    width: 100%;
}

.login__form > input {
    width: 95%;
    margin-bottom: 5%;
}

.login__form > input,
.login__form > button  {
    height: 40px;
}

.login__form > button  {
    background-color: #ff00c3;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.login__form > button:hover  {
    background-color: #a100ff;
}

@media (min-width: 625px) {
    .login__dashboard {
        margin: 5% 20%;
    }

    .login__form > input {
        width: 97.5%;
        padding-left: 1.5%;
    }

    .login__header > img {
        width: 15%;
    }

    .login__header {
        padding: 2.5%;
    }

    .login__form > input {
        margin-bottom: 2.5%;
    }
}

@media (min-width: 1000px) {
    .login__form > input {
        width: 98.5%;
    }

    .login__header > img {
        width: 12%;
    }
}
