.Anchor {
    padding: 5% 0;
}
.mobile__anchor {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-gap: 10px; /* Adjust gap as needed */
    justify-items: center;
  }

  .cta, .quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2b5eaf;
    color: #f2f2f2;
    width: 100%;
    height: 160px;
  }

  .quote {
    padding: 5% 0;
  }
  
  .cta > h2,
  .quote > h2,
  .tablet__cta {
    padding: 0 5%;
    text-align: center;
  }

  .quote2 {
    padding: 15% 0;
  }

  .cta > a > button,
  .tablet__cta > a > button,
  .desktop__cta > a > button {
    color: #ff00c3;
    background-color: #fff;
    border: 2px solid #a100ff;
    width: 100%; /* Adjust width as needed */
    height: 100%; /* Fixed height */
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px; /* Adjust font size as needed */
    padding: 10px 20px; /* Adjust padding as needed */
  }
  
  .cta > a > button:hover,
  .tablet__cta > a > button:hover,
  .desktop__cta > a > button:hover {
    background-color: #a100ff;
    color: white;
  }
  
  .cta, .quote {
    grid-column: 1 / -1; /* Span across both columns */
  }
  
  .anchor__img__container {
    display: contents; /* Use display: contents to have .anchor__img use the grid of .mobile__anchor */
  }
  
  .anchor__img {
    grid-column: span 1; /* Each .anchor__img takes up 1 column */
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .anchor__img > img {
    width: 100%;
  }

@media (min-width: 750px) {
    .tablet__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 1 / 1 / 2 / 2; }
        .div2 { grid-area: 1 / 2 / 2 / 3; }
        .div3 { grid-area: 2 / 1 / 3 / 2; }
        .div4 { grid-area: 2 / 2 / 3 / 3; }
        .div5 { grid-area: 3 / 1 / 4 / 2; }
        .div6 { grid-area: 3 / 2 / 4 / 3; }

    .tablet__img__container {
        display: flex;
    }

    .tablet__img__container > img {
        width: 100%;
    }

    .quote__container,
    .desktop__quote {
        background-color: #2b5eaf;
        color: #f2f2f2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5%;
    }

    .tablet__cta,
    .desktop__cta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tablet__cta > button,
    .desktop__cta > button {
        height: 10%;
    }
}

@media (min-width: 1000px) {
    .desktop__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        padding: 0 5%;
        }
        
        .div1 { grid-area: 1 / 1 / 2 / 2; }
        .div2 { grid-area: 1 / 2 / 2 / 3; }
        .div3 { grid-area: 1 / 3 / 2 / 4; }
        .div4 { grid-area: 2 / 1 / 3 / 2; }
        .div5 { grid-area: 2 / 2 / 3 / 3; }
        .div6 { grid-area: 2 / 3 / 3 / 4; }
        .div7 { grid-area: 3 / 1 / 4 / 2; }
        .div8 { grid-area: 3 / 2 / 4 / 3; }
        .div9 { grid-area: 3 / 3 / 4 / 4; }

    .desktop__img > img {
        width: 100%;
    }

    .desktop__cta > h2 {
        text-align: center;
        margin-bottom: 5%;
    }

    .desktop__cta > button {
        width: 70%;
    }

    .large__quote > h2 {
        font-size: 18px;
        padding-bottom: 5%;
    }
    
    .desktop__quote, .desktop__img, .desktop__cta {
        box-sizing: border-box;
    }

    .desktop__quote > *, .desktop__img > *, .desktop__cta > * {
        margin: 0; /* Removes default margins from elements like <h2> and <p> */
        padding: 0; /* Adjust as necessary */
    }

    /* Reapply any desired padding or margin specifically rather than globally */
    .desktop__quote, .desktop__cta {
        padding: 0 5%;
    }

    .desktop__cta > button {
        margin: 10px auto; /* Example of reapplying margin specifically */
        width: 70%;
    }
}