.STEP > h2 {
    text-align: center;
    color: #ff00c3;
}
.step__info {
    padding: 0 5%;
    text-align: center;
}

.step__info > img {
    width: 100%;
    height: auto;
}

.step__info > h1 {
    color: #a100ff;
}

.step__info > h1 > span {
    color: #ff00c3;
}

.step__info > h6 {
    font-size: 16px;
    margin: 0;
}

.step__info > p {
    padding-bottom: 5%;
}

@media (min-width: 750px) {
    .STEP > h2 {
        font-size: 28px;
    }
    .step__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .step_div1 { grid-area: 1 / 1 / 2 / 2; }
        .step_div2 { grid-area: 1 / 2 / 2 / 3; }
        .step_div3 { grid-area: 2 / 1 / 3 / 2; }
        .step_div4 { grid-area: 2 / 2 / 3 / 3; }
        .step_div5 { grid-area: 3 / 1 / 4 / 2; }
        .step_div6 { grid-area: 3 / 2 / 4 / 3; }

        .step__info > img {
            height: auto;
        }

        .step__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
}


@media (min-width: 1000px) {
        .step__container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, auto);
            gap: 20px; /* Adjust the gap between grid items as needed */
          }
          
          .step_div1 { grid-area: 1 / 1 / 2 / 2; }
          .step_div2 { grid-area: 2 / 1 / 3 / 2; }
          .step_div3 { grid-area: 1 / 2 / 2 / 3; }
          .step_div4 { grid-area: 2 / 2 / 3 / 3; }
          .step_div5 { grid-area: 1 / 3 / 2 / 4; }
          .step_div6 { grid-area: 2 / 3 / 3 / 4; }
          
}